import { SectionProp } from "../../interfaces/SectionProp";
import "./index.scss";

const TrackLogo = () => {
	return (
		<>
			<img className={"trackLogo"} src={"https://assets.sentrack.com.au/images/sen-track-logo.png"} alt="Track app mockups" />
			<p className={"greenText uppercase catchPhrase"}>Get The most from race day with SEN Track's free punting and gaming platforms</p>
		</>
	);
};

const Mockups = () => {
	return <img className={"mockup"} src={"https://assets.sentrack.com.au/images/landing-mockup.png"} alt="Track app mockups" />;
};

export const Main = (props: SectionProp) => {
	return (
		<section id={"trackSection"}>
			<div className={"container"}>
				<div className={"containerInner"}>
					{!props.isMobile ? (
						<div className={"autoGrid"}>
							<li>
								<Mockups />
							</li>
							<li>
								<div className={"displayTable"}>
									<div className={"containerInner"}>
										<TrackLogo />
									</div>
								</div>
							</li>
						</div>
					) : (
						<div className={"displayTable"}>
							<div className={"containerInner"}>
								<TrackLogo />
								<Mockups />
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
