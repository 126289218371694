import React, { useLayoutEffect, useState } from "react";
import "./index.scss";
import { Quaddie } from "./cards/Quaddie/Quaddie";
import { SameRacer } from "./cards/SameRacer/SameRacer";
import { Survivor } from "./cards/Survivor/Survivor";
import { Main } from "./cards/Main/Main";
import { Landing } from "./cards/Landing/Landing";

function App() {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 750);

	useLayoutEffect(() => {
		function updateSize() {
			setIsMobile(window.innerWidth <= 750);
		}

		function updateNavColor() {
			const currentSection = document.elementsFromPoint(0, 0).find((x) => x.id.includes("Section"));
			if (currentSection) {
				let bgCol = window.getComputedStyle(currentSection).backgroundColor;

				// Handle linear-gradient for survivor screen
				if (window.getComputedStyle(currentSection).background.includes("linear-gradient")) {
					bgCol = "rgb(254, 81, 46)";
				}

				document.querySelector('meta[name="theme-color"]')!.setAttribute("content", bgCol);
			}
		}

		window.addEventListener("resize", updateSize);
		window.addEventListener("scroll", updateNavColor);

		updateSize();

		return () => {
			window.removeEventListener("resize", updateSize);
			window.addEventListener("scroll", updateNavColor);
		};
	});

	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	document.documentElement.style.setProperty("--default-font-size", isMobile ? `3vw` : "1.5vw");

	return (
		<>
			<header></header>
			<Landing isMobile={isMobile} />
			<Main isMobile={isMobile} />
			<SameRacer isMobile={isMobile} />
			<Quaddie isMobile={isMobile} />
			<Survivor isMobile={isMobile} />
		</>
	);
}

export default App;
