import { SectionProp } from "../../interfaces/SectionProp";
import { Label } from "../Label";
import { QrCode } from "../QrCode";

import "./index.scss";
const link = "https://thequaddie.com.au";

const TopSection = () => {
	return (
		<>
			<img className={"q"} src={"https://assets.sentrack.com.au/images/quaddie-logo.png"} alt={"Quaddie Logo"} />
			<div className={"catchPhrase"}>
				<p className={"uppercase mobileText"}>
					All your Quaddies
					<br />
					and racing content
					<br />
					in one place
				</p>
			</div>
		</>
	);
};

const BottomSection = () => {
	return (
		<a href={link} target="_blank" rel="noreferrer">
			<img className={"mockup"} src={"https://assets.sentrack.com.au/images/quaddie-mockup.png"} alt={"Quaddie iPhone screenshot"} />
		</a>
	);
};

export const Quaddie = (props: SectionProp) => {
	return (
		<section id={"quaddieSection"}>
			<div className={"container"}>
				<div className={"containerInner"}>
					{!props.isMobile ? (
						<div className={"autoGrid"}>
							<li>
								<BottomSection />
							</li>
							<li>
								<div className={"displayTable"}>
									<div className={"containerInner"}>
										<TopSection />
										<QrCode link={link} />
									</div>
								</div>
							</li>
						</div>
					) : (
						<div className={"displayTable"}>
							<div className={"containerInner"}>
								<TopSection />
								<BottomSection />
								<Label text={"Download Now"} link={link} />
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
