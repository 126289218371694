import { useEffect, useState } from "react";
const QRCode = require("qrcode");

export const QrCode = (props: { link: string }) => {
	const [qrBlob, setQrBlob] = useState(null);

	useEffect(() => {
		QRCode.toDataURL(props.link, { color: { dark: "#000000", light: "#ffffff" } })
			.then((url: any) => {
				setQrBlob(url);
			})
			.catch((err: any) => {
				console.log(err);
			});
	});

	if (!qrBlob) return <></>;

	return (
		<a href={props.link} target="_blank" rel="noreferrer">
			<img style={{ marginTop: "var(--paragraph-margin-top)", borderRadius: "10px" }} src={qrBlob} alt={props.link} />
		</a>
	);
};
