import { SectionProp } from "../../interfaces/SectionProp";
import { Label } from "../Label";
import { QrCode } from "../QrCode";
import "./index.scss";

const link = "https://sameracer.com.au";

const TopSection = () => {
	return (
		<>
			<img className={"sr"} src={"https://assets.sentrack.com.au/images/same-racer-logo-left.png"} alt={"Same Racer Logo"} />
			<div className={"poweredBy"}>
				<p className={"uppercase displayInline"}>Powered by</p>
				<img className={"bb displayInline"} src={"https://assets.sentrack.com.au/images/bluebet-logo-small.png"} alt={"Bluebet Logo"} />
			</div>
			<div className={"catchPhrase"}>
				<p className={"uppercase mobileText"}>
					The home of <br />
					same race multis
				</p>
			</div>
		</>
	);
};

const BottomSection = () => {
	return (
		<a href={link} target="_blank" rel="noreferrer">
			<img
				className={"sameRacerMockup mockup"}
				src={"https://assets.sentrack.com.au/images/sameracer-mockup.png"}
				alt={"Same Racer iPhone screenshot"}
			/>
		</a>
	);
};

export const SameRacer = (props: SectionProp) => {
	return (
		<section id={"sameRacerSection"}>
			<div className={"container"}>
				<div className={"containerInner"}>
					{!props.isMobile ? (
						<div className={"autoGrid"}>
							<li>
								<div className={"displayTable"}>
									<div className={"containerInner"}>
										<TopSection />
										<QrCode link={link} />
									</div>
								</div>
							</li>
							<li>
								<BottomSection />
							</li>
						</div>
					) : (
						<div className={"displayTable"}>
							<div className={"containerInner"}>
								<TopSection />
								<BottomSection />
								<Label text={"Download Now"} link={link} />
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
