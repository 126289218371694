import { SectionProp } from "../../interfaces/SectionProp";
import { Label } from "../Label";
import { QrCode } from "../QrCode";
import "./index.scss";

const link = "https://www.sen.com.au/app/";

const Logos = () => {
	return (
		<>
			<img className={"senGroupLogo"} src={"https://assets.sentrack.com.au/images/SEN-Track-Logo-Group-Sized.png"} alt="Landing Mockup" />

			<p className={"whiteText uppercase catchPhrase"}>Get Everything Track In The SEN App</p>
		</>
	);
};
const Mockups = () => {
	return <img className={"mockup"} src={"https://assets.sentrack.com.au/images/Track-Mockup-Sized.png"} alt="Track app mockups" />;
};
export const Landing = (props: SectionProp) => {
	return (
		<section id={"landingSection"}>
			<div className={"container"}>
				<div className={"containerInner"}>
					{!props.isMobile ? (
						<div className={"autoGrid"}>
							<li>
								<div className={"displayTable"}>
									<div className={"containerInner"}>
										<Logos />
										<QrCode link={link} />
									</div>
								</div>
							</li>
							<li>
								<Mockups />
							</li>
						</div>
					) : (
						<div className={"displayTable"}>
							<div className={"containerInner"}>
								<Logos />
								<Mockups />
								<Label text={"Download Now"} link={link} />
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
