import { SectionProp } from "../../interfaces/SectionProp";
import { Label } from "../Label";
import "./index.scss";

const link = "https://sensurvivor.com.au";

const TopSection = () => {
	return (
		<>
			<img className={"s"} src={"https://assets.sentrack.com.au/images/survivor-logo.png"} alt={"Survivor Logo"} />
			<p className={"uppercase catchPhrase mobileText"}>
				Tip your way through a range
				<br />
				of knockout competitions.
				<br />
				Stay alive to win big!
			</p>
		</>
	);
};

const BottomSection = (props: SectionProp) => {
	return (
		<a href={link} target="_blank" rel="noreferrer">
			<img
				className={"mockup"}
				src={`https://assets.sentrack.com.au/images/survivor-${props.isMobile ? "" : "laptop-"}mockup.png`}
				alt={"Survivor screenshot"}
			/>
		</a>
	);
};

export const Survivor = (props: SectionProp) => {
	return (
		<section id={"survivorSection"}>
			<div className={"container"}>
				<div className={"containerInner"}>
					{!props.isMobile ? (
						<div className={"autoGrid"}>
							<li>
								<div className={"displayTable"}>
									<div className={"containerInner"}>
										<TopSection />
										<Label text={"Join Today"} link={link} />
									</div>
								</div>
							</li>
							<li>
								<BottomSection {...props} />
							</li>
						</div>
					) : (
						<div className={"displayTable"}>
							<div className={"containerInner"}>
								<TopSection />
								<BottomSection {...props} />
								<Label text={"Join Today"} link={link} />
							</div>
						</div>
					)}
				</div>
			</div>
		</section>
	);
};
